export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white"}
  },
  { key: "fullname", label: "ชื่อพนักงาน", sortable: true , thStyle:{color:"white"}},
  { key: "opener", label: "จำนวนใบงานที่เปิด", sortable: true ,thStyle:{color:"white"}},
  { key: "assignee", label: "จำนวนใบงานที่ปิด", sortable: true , thStyle:{color:"white"}},
  { key: "sum", label: "รวม", sortable: false , thStyle:{color:"white"}},
];
