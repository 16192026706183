export default [
  {
    key: "branchCode",
    label: "Site ID",
    thStyle:{color:"white"}
  },
  { key: "branchName", label: "Site Name", sortable: true , thStyle:{color:"white", width: "10% !important"}},
  { key: "ticketNo", label: "Job No", sortable: true ,thStyle:{color:"white", width: "8% !important"}},
  { key: "openTicketDate", label: "Open Date", sortable: true , thStyle:{color:"white", width: "10% !important"}},
  { key: "closeTicketDate", label: "Finish Date", sortable: true , thStyle:{color:"white", width: "10% !important"}, },
  {
    key: "problemTypeName",
    label: "Problem type",
    sortable: false,
    thStyle: { width: "10% !important", textAlign: "center" ,color:"white"},
  },
  { key: "problemDetail", label: "Problem", sortable: false ,thStyle:{color:"white",width: "20% !important"}},
  {
    key: "problemSolving",
    label: "Detail",
    sortable: false,
    thStyle: { width: "15% !important", textAlign: "center", color:"white",width: "20% !important" },
  },
  {
    key: "assignTo",
    label: "Resolve by",
    sortable: false,
    thStyle: { width: "10% !important", textAlign: "center", color:"white" },
  },
  {
    key: "slaStatus",
    label: "SLA",
    sortable: false,
    thStyle: { width: "3% !important", textAlign: "center", color:"white" },
  },
];
