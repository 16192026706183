<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="6" class="my-1">
            <h2 class="text-primary"><b>Closed Tickets (ใบงานทั้งหมด {{ showNumberItem }} ใบงาน)</b></h2>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form inline class="d-flex justify-content-md-end align-items-center">
              <b-input-group class="input-group-merge flex-grow-1 mb-2 mb-md-0" >
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="พิมพ์คำที่ต้องการค้นหาและกด Enter"
                  v-model="searchText"
                  @keydown.enter.prevent="search"
                />
                <b-input-group-append is-text @click="search">
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
              <div class="d-flex flex-wrap">
              <b-button                
                variant="outline-primary"
                class="ml-1 shadow"   
                title="คลิกเพื่อ Reload รายการ"
                @click="getTicketLists('',true)"                        
                ><feather-icon icon="RefreshCwIcon" size="14"
              />  </b-button>

              <b-button variant="outline-primary" class="ml-1 shadow" v-b-modal.search-more-modal
                >Search more <feather-icon icon="FilterIcon" size="14"
              /></b-button>
              </div>
            </b-form>
          </b-col>

   

          <!--START TABLE -->
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow text-center"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading.ticket"
              selectable
              select-mode="single"
              @row-selected="onSelectTickett"              
            >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(sla)="row">
                <center>
                  <feather-icon
                    v-if="row.item.sla == 'wsla'"
                    icon="CheckCircleIcon"
                    class="cursor-pointer text-success"
                  />
                  <feather-icon
                    v-else
                    icon="XCircleIcon"
                    class="cursor-pointer text-danger"
                  />
                </center>
              </template>

              <template #cell(openTicketDate)="row">
                {{ showDate(row.item.openTicketDate, "original") }}
              </template>  
              <template #cell(closeTicketDate)="row">
                {{ showDate(row.item.closeTicketDate , "original") }}
              </template>                            
            </b-table>
          </b-col>
          <!--END TABLE -->

          <b-col cols="6" md="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" md="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>

        <div v-if="ticketPermission=='view'">
        <b-row class="mt-3">
          <b-col>
            <h3 class="text-secondary">สรุปการทำงาน</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4">
            <!--START TABLE -->
            <b-table
              style="font-size: 12px"
              class="shadow "
              small
              striped
              bordered
              hover
              responsive  
              :items="summaryItems"
              :fields="summaryFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading.summary"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(opener)="row">
                <center class="text-right">{{ row.item.results.opener }}</center>
              </template>

              <template #cell(assignee)="row">
                <center class="text-right">{{ row.item.results.assignee}}</center>
              </template>

              <template #cell(sum)="row">
                <center class="text-right">{{ row.item.results.opener + row.item.results.assignee}}</center>
              </template>

              <template slot="bottom-row" slot-scope="data">
                <td colspan="2" class="text-center"><b>ยอดรวม</b></td>
                <td class="text-right"><b>{{ ticketSummaryTotal.openner}}</b></td>
                <td class="text-right"><b>{{ ticketSummaryTotal.assignee}}</b></td>
                <td class="text-right"><b>{{ ticketSummaryTotal.sum}}</b></td>
              </template>            </b-table>

            <!--END TABLE -->
          </b-col>
          <b-col cols="12" md="4" class="text-center">
            <h3>Helpdesk</h3>
            <chartjs-bar-chart v-if="!isLoading.summary" :data="chartDatas.helpdesk" class="mt-2"/>
          </b-col>
          <b-col cols="12" md="3" class="text-center" v-if="false">
            <h3>CM</h3>
            <chartjs-bar-chart  :data="chartDatas.cm" class="mt-2"/>
          </b-col>
        </b-row>
      </div>
      </b-card>
    </b-col>
    <SearchMoreModal :getTicketLists="getTicketLists"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BCard,
  BForm,
  BSpinner,
  BTr,
  BTh,
} from "bootstrap-vue";
import ChartjsBarChart from "./chart/chartjsBarChart.vue";
import moment  from "moment"
import commaNumber from 'comma-number'

import Field from "./field";
import SummaryField from "./field/summary";
import SearchMoreModal from "./components/modal/searchMoreModal"

import TicketService from "@/services/ticket";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    BTr,
    BTh,    
    ChartjsBarChart,
    SearchMoreModal
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      searchText:"",

      fields: Field,
      summaryFields: SummaryField,
      items: [],
      summaryItems: [],
      ticketSummaryTotal:{},
      chartDatas: {
        helpdesk: {
          max:0,
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: "#28dac6",
              borderColor: "transparent",
            },
          ],
        },
        // cm: {
        //   labels: [
        //     "ประพล พันธ์สมจิตร",
        //     "ทินกร ทองอ่อน",
        //     "ทินกร ทองอ่อน",
        //     "ชาตรี ",            
        //   ],
        //   datasets: [
        //     {
        //       data: [350, 275, 198, 256],
        //       backgroundColor: "#4F5D70",
        //       borderColor: "transparent",
        //     },
        //   ],
        // },        
      },
      isLoading:{
        ticket:false,
        summary:false
      }
    };
  },
  async created(){
    await this.getTicketLists()  
    // await this.getTicketSummary()
  },
  computed: {
    ticketPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("TK004")      
      return permission
    },
    closedTicketPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("TK003")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    showNumberItem(){
      return commaNumber(this.items.length)
    }
  },

  methods: {
    async search(){
      if(!this.searchText){
        return
      }
      this.isLoading.ticket = true;
      const result = await TicketService.SearchClosedTicket(this.searchText)
      this.items = result.data.data;
      this.$store.commit("ticket/UPDATE_SEARCH_TEMP", null)
      this.totalRows = this.items.length;
      this.isLoading.ticket = false;
    },
    async getTicketLists(queryString="",isClear=false) {
      try {
        this.isLoading.ticket = true;
        const searchTemp = this.$store.getters["ticket/getSearchTemp"]
        if(searchTemp){
            queryString = searchTemp.queryString
        }

        if(isClear){
          queryString = ""
        }

        const result = await TicketService.GetTicketClosedList({}, queryString);
        this.items = result.data.data;
        this.totalRows = this.items.length;
        this.isLoading.ticket = false;

        await this.getTicketSummary(queryString)
      } catch (error) {      
        alert("Load data error.");
      }
    },
    async getTicketSummary(queryString="") {
      try {
        this.isLoading.summary = true;
        const result = await TicketService.GetTicketClosedSummary(queryString);
        this.summaryItems = result.data.data;     
        this.ticketSummaryTotal = result.data.total;    
        this.buildGraph(result.data.data)
        this.isLoading.summary = false;
      } catch (error) {      
        alert("Load data error.");
      }
    },

    onSelectTickett(item) {
      console.log("item selected", item);
      this.$router.push(`/ticket/edit/${item[0].ticketId}`);
    },

    showDate(date, type="") {
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="original"){
        return moment(date).subtract(7, 'h').format("DD-MM-yyyy HH:mm:ss");
      }

      if(type=="subtract"){
        return moment(date).subtract(7, 'h')
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },

    buildGraph(datas){   
      let names = []
      let sumList = []
      let max = 0
      for (let index = 0; index < datas.length; index++) {
        const item = datas[index];     
        const sum = item.results.opener+ item.results.assignee
        names.push(item.fullname)
        sumList.push(sum)

        if(max<sum){
          max = sum
        }
      }

      this.chartDatas.helpdesk.labels = names
      this.chartDatas.helpdesk.datasets[0].data = sumList
      this.chartDatas.helpdesk.max = max  
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  mounted() {
      
    this.totalRows = this.items.length;
    
  },  
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
