<template>
  <div>
    <b-modal ref="search-more-modal" id="search-more-modal" title="ค้นหา" size="lg" hide-footer no-close-on-backdrop>
      <b-card-text>
        <b class="text-primary">ค้นหาด้วยข้อมูลพื้นฐาน</b>
        <b-row class="my-2">
          <b-col>
            <label>ค้นหาด้วยการพิมพ์</label>
            <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="พิมพ์คำที่ต้องการค้นหา"
                  v-model="searchText"
                   @keydown.enter.prevent="onSearch"
                />
          </b-col>
        </b-row>
        <!--- START (วันที่) --->
        <b-row>
          <b-col md="6" class="">
            <label>วันที่เริ่มต้น (วันที่ปิดใบงาน)</label>
            <b-form-datepicker
              id="example-datepicker"
              locale="th-TH"
              v-model="dateStart"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          <b-col md="6" class="">
            <label>วันที่สิ้นสุด</label>
            <b-form-datepicker
              id="example-datepicker2"
              locale="th-TH"
              v-model="dateEnd"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
        </b-row>
        <!--- END (วันที่) --->

        <!--- START (ประเภทงาน) --->
        <b-row class="mb-2">
          <b-col cols="6">
            <label>ประเภทปัญหา</label>
            <v-select
              id="brand"
              v-model="problemTypeSelected"
              label="title"
              :options="problemTypeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
          <b-col cols="6">
            <label>ผู้รับงาน (ช่าง)</label>
            <v-select
              id="brand"
              v-model="employeeSelected"
              label="fullname"
              :options="employeeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <!--- END (ประเภทงาน) --->

       
        
        <b class="text-primary">ค้นหาด้วยลูกค้า</b>
        <!--- START (ลูกค้า) --->
        <b-row>
          <b-col cols="6">
            <label>ลูกค้า</label>
            <v-select
              id="brand"
              v-model="ownerSelected"
              label="ownerName"
              :options="ownerOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerOwner"
            />
          </b-col>
          <b-col cols="6" v-if="ownerSelected.id!==0">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="companySelected"
              label="companyName"
              :options="companyOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerCompany"
            />
          </b-col>          
        </b-row>
        <!--- END (ลูกค้า) --->

        <!--- START (ลูกค้า) --->
        <b-row >
          <b-col cols="6" v-if="ownerSelected.id!==0 && companySelected.id!==0">
            <label>บริษัท</label>
            <v-select
              id="brand"
              v-model="subCompanySelected"
              label="subCompanyName"
              :options="subCompanyOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeCustomerSubCompany"
            />
          </b-col>
          <b-col cols="6" v-if="ownerSelected.id!==0 && companySelected.id!==0 && subCompanySelected.id!=0">
            <label>สาขา</label>
            <v-select
              id="brand"
              v-model="branchSelected"
              label="branchName"
              :options="branchOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>          
        </b-row>
        <!--- END (ลูกค้า) --->       


        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-2">
          <b-col md="12" class="d-flex justify-content-end">
            
            <b-button class="mr-1" variant="primary" size="sm" @click="onSearch">ค้นหา</b-button>
            <b-button class="" variant="danger" size="sm" @click="onClearSearch">ล้างการค้นหา</b-button>
            
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
 
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";

import UserService from "@/services/user";
import CustomerService from "@/services/setting/customer";

export default {
  props:['getTicketLists'],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    BButton
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      employeeSelected: {fullname:"เลือกทั้งหมด", userId:0},
      problemTypeSelected: { id: 0, title: "เลือกทั้งหมด", value: 0 }, 
      employeeOption: [],
      problemTypeOption: [
        { id: 0, title: "เลือกทั้งหมด", value: 0 },
        { id: 1, title: "Hardware problem", value: 1 },
        { id: 3, title: "Software problem", value: 3 },
        { id: 4, title: "Setup hardware", value: 4 },
        { id: 5, title: "Setup software", value: 5 },
        { id: 7, title: "User problem", value: 7 },
        { id: 8, title: "Other Problem", value: 8 },
      ],
      ownerSelected: {ownerName:"เลือกทั้งหมด", id:0},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],  
      branchOption: [],
      branchSelected: {},   
      dateStart: "",
      dateEnd: "",
      searchText:""
    };
  },
  computed: {
    customerRolePermission() {
      const permission =  this.$store.getters["user/getCustomerRolePermission"]("ST005-1");
        return permission
    },     
  },
  created(){
    this.getUsers()
    this.getCustomerOwner()
    const searchTemp = this.$store.getters["ticket/getSearchTemp"]
    this.dateStart = searchTemp.data.dateStart
    this.dateEnd = searchTemp.data.dateEnd
    this.problemTypeSelected = searchTemp.data.problemTypeSelected
    
    this.branchSelected = searchTemp.data.branchSelected
    this.ownerSelected = searchTemp.data.ownerSelected
    this.subCompanySelected = searchTemp.data.subCompanySelected
    this.searchText = searchTemp.data.searchText

    if(searchTemp.data.employeeSelected){
      this.employeeSelected = searchTemp.data.employeeSelected
    }else{
      this.employeeSelected = {fullname:"เลือกทั้งหมด", userId:0}
    }
    


  },
  methods:{
    onSearch(){

      let queryString = []
      if(this.dateStart&&this.dateEnd){
        queryString.push(`dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`)
      }

      if(this.problemTypeSelected.id!==0){
        queryString.push(`problemType=${this.problemTypeSelected.id}`)
      }

      if(this.employeeSelected.userId!==0){
        queryString.push(`assignTo=${this.employeeSelected.userId}`)
      }

      //Customer Search
      if(this.ownerSelected.id && this.ownerSelected.id !== 0){
        queryString.push(`ownerId=${this.ownerSelected.id}`);
      }

      if(this.companySelected.id && this.companySelected.id !== 0){
        queryString.push(`companyId=${this.companySelected.id}`);
      }

      
      if(this.subCompanySelected.id && this.subCompanySelected.id !== 0){
        queryString.push(`subCompanyId=${this.subCompanySelected.id}`);
      }

      if(this.branchSelected.id && this.branchSelected.id !== 0){
        queryString.push(`customerId=${this.branchSelected.id}`);
      }


      if(this.searchText!==""){
        queryString.push(`searchText=${this.searchText}`);
      }

      queryString.push(`isSearch=true`);

      const joined = queryString.join("&")


      const searchTempObj = {
        dateStart:this.dateStart,
        dateEnd: this.dateEnd,
        problemTypeSelected:this.problemTypeSelected,
        employeeSelected: this.employeeSelected,
        branchSelected:this.branchSelected,
        ownerSelected:this.ownerSelected,
        subCompanySelected: this.subCompanySelected,
        searchText:this.searchText
      }
      this.$store.commit("ticket/UPDATE_SEARCH_TEMP", {data:searchTempObj, queryString:joined})
   
      this.getTicketLists(joined)
      this.hideModal()
    }, 
    onClearSearch(){
      this.employeeSelected = {fullname:"เลือกทั้งหมด", userId:0}
      this.problemTypeSelected =  { id: 0, title: "เลือกทั้งหมด", value: 0 }
      this.ownerSelected = {ownerName:"เลือกทั้งหมด", id:0}
      this.companySelected = {}
      this.subCompanySelected= {}
      this.branchSelected =  {}
      this.dateStart= ""
      this.dateEnd =  ""
      this.searchText=""
    },
    async getUsers(){      
      const result = await UserService.GetUserList({})
      this.employeeOption.push({fullname:"เลือกทั้งหมด", userId:0})
      this.employeeOption = [...this.employeeOption, ...result.data.data]  
      
    },
    async getCustomerOwner() {
      const result = await CustomerService.GetCustomerOwnerList({});
      this.ownerOption.push({ownerName:"เลือกทั้งหมด", id:0})
      this.ownerOption = [...this.ownerOption, ...result.data.data];

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.ownerId !==0){    
          this.ownerOption = this.ownerOption.filter(option=>option.id == this.customerRolePermission.ownerId && option.id !==0)        
        }
      }


      if (result.data.data.length > 0) {
        let item = this.ownerOption[0];  
        this.ownerSelected = item
        this.onChangeCustomerOwner(item);
      }else{
        this.ownerSelected = {}
      }
    },

    async getCustomerCompany(ownerObj) {
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);
      this.companyOption = [{id:0, companyName:"==== ทั้งหมด ===="}, ...result.data.data];

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){      
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.companyId !==0){            
          this.companyOption = this.companyOption.filter(option=>option.id == this.customerRolePermission.companyId && option.id !==0)        
        }
      }

      if (result.data.data.length > 0) {
        let item = this.companyOption[0];        
        this.companySelected = item;
        this.onChangeCustomerCompany(item);
      }else{
        this.companySelected = {}
      }
    },

    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );
      this.subCompanyOption = [{id:0, subCompanyName:"==== ทั้งหมด ===="}, ...result.data.data];       

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.subCompanyId !==0){     
          this.subCompanyOption = this.subCompanyOption.filter(option=>option.id == this.customerRolePermission.subCompanyId && option.id !==0)  
        }
      }

      if (result.data.data.length > 0) {    
        let item = this.subCompanyOption[0];         
        this.subCompanySelected =item; 
        this.onChangeCustomerSubCompany(item);
      }else{
        this.subCompanySelected = {}
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id
      );
      this.branchOption = [{id:0, branchName:"==== ทั้งหมด ===="},... result.data.data];
      if (result.data.data.length > 0) {      
        this.branchSelected = this.branchOption[0];        
      }else{
        this.branchSelected = {}
      }
    },    
    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },
    hideModal() {
      this.$refs['search-more-modal'].hide()
    },
  }
};
</script>

<style>
@import "./style.css";
</style>
